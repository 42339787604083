import { Note } from '../../core/note';
import { Degree, Finger, Interval, Inversion, Marker, NoteName, Position, ScaleType } from '../../core/types';
import { LanguageKey } from '../../services/translate.service';

export enum Instrument {
  violin = 'violin',
  viola = 'viola',
  cello = 'cello',
  bass = 'bass',
  guitar = 'guitar'
}

export interface BaseSettings {
  instrument?: Instrument;
  showFingering?: boolean;
  showNoteSymbol?: boolean;
  showSolmisationName?: boolean;
  clefMode?: ClefMode;
}

export interface ScaleSettings {
  keyNoteName?: NoteName;
  scaleType?: ScaleType;
}

export interface PositionSettings {
  showPositionLabels?: boolean;
  position?: Position;
  showWidePositions?: boolean;
  showDefaultFingering?: boolean;
}

export interface IntervalSettings {
  showAllIntervals?: boolean;
  highlightIntervals?: Interval[];
  highlightChords?: ChordMetaInfo;
}

export interface SaveSettings {
  autoSave?: boolean;
}

export interface LanguageSettings {
  language?: LanguageKey;
}

export interface FingerBoardItemDisplayOptions extends BaseSettings {
  showNoteName?: boolean;
  showSolmisationName?: boolean;
  intervalNameDisplayMode?: IntervalNameDisplayMode;
}

export interface FingerBoardItemData {
  note: Note;
  isStartNote: boolean;
  solmisationName: string;
  position: Position;
  highlightGuideAtPosition: Position;
  itemInPositionState: ItemInPositionState;
  clef: Clef;
  lastClef: Clef;
  clefBasedStartNoteIndex: number;
  showClef: boolean;
  interval: Interval;
  intervalNameDisplayMode: IntervalNameDisplayMode,
  highlightIntervals: Interval[];
  finger: Finger;
  marker: Marker;
  highlightChords: ChordMetaInfo;
  degree: Degree;
}

export enum Clef {
  treble = 'treble',
  tenor = 'tenor',
  alto = 'alto',
  bass = 'bass',
  treble8 = 'treble8'
}

export type ClefMode = 'auto' | Clef;

export enum ItemInPositionState {
  normal = 'normal',
  wide = 'wide',
  none = 'none'
}

export enum IntervalNameDisplayMode {
  never = 'never',
  show = 'show',
  hide = 'hide'
}

export interface FingerBoardItemStyles {
  ['--fingering-circle-color']: string;
  ['--fingering-circle-bg-color']: string;
  ['--fingering-string-color']: string;
  ['--guide-color']: string;
  ['--item-bg-color']: string;
  ['--note-name-bg-color']: string;
  ['--degree-name-bg-color']: string;
  ['--note-symbol-offset']: string;
  ['--note-symbol-height']: string;
}

export interface ChordMetaInfo {
  degree: Degree;
  inversion?: Inversion;
  noteInfos: ChordNoteInfo[];
  addSeventh?: boolean;
}

export interface ChordNoteInfo {
  noteName: string;
  octavedBy?: number;
}

export enum IntervalSettingsMode {
  intervals = 'intervals',
  chords = 'chords'
}
